import axios from "axios";
import { getHeaders } from "./headers";

export const requestGraphQL = ({ headers, data } = {}) => {
  const configRequest = {
    method: "post",
    credentials: "same-origin",
    "Access-Control-Allow-Origin": "*",
    headers: getHeaders({ customHeaders: headers }),
    url: "/api/graphql",
    data: data,
  };

  return axios(configRequest.url, configRequest)
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
};
