class InputSearchExpand {
  constructor() {
    this.navItem = ".js-expand-search-item";
    this.input = ".js-search-form-input";
  }

  toggle(type) {
    const navItems = document.querySelectorAll(this.navItem) || [];
    const filteredItems = [...navItems].filter((item) => item.id !== "nav-courses");
    /* eslint-disable no-param-reassign */
    filteredItems.forEach((item) => {
      item.hidden = type === "focus";
    });
    /* eslint-enable no-param-reassign */
  }

  bind() {
    const searchFormInput = document.querySelector(this.input);
    searchFormInput?.addEventListener("focus", () => this.toggle("focus"));
    searchFormInput?.addEventListener("blur", () => this.toggle("blur"));
  }

  init() {
    this.bind();
  }
}

try {
  Domestika.inputSearchExpand = new InputSearchExpand();
  Domestika.inputSearchExpand.init();
} catch (error) {
  console.error("[Domestika.inputSearchExpand]", error);
}
