class FixedAlert {
  handle() {
    const closeAdviceButton = document.querySelector(".js-close-fixed-alert");
    if (closeAdviceButton) {
      closeAdviceButton.addEventListener("click", (e) => this.hideAdvice(e));
    }
  }

  hideAdvice(event) {
    const target = event.target.dataset.closeTargetAttr;
    const advice = document.querySelector(`[${target}]`);
    this.close = advice.classList.add("d-none");
  }
}

Domestika.fixedAlert = new FixedAlert();

Domestika.fixedAlert.handle();
