import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["days", "hours", "minutes", "seconds", "daysBlock"];

  static values = {
    endsAt: String,
    hideZeroDays: Boolean,
    paddingZeroes: Number,
    daysPaddingZeroes: Number,
  };

  static timer;

  static endsAtTime;

  connect() {
    this.endsAtTime = this.endsAtValue.replace(/-/g, "/");

    this.timer = setInterval(() => {
      this.update();
    }, 1000);
  }

  update() {
    const remainingSeconds = +new Date(this.endsAtTime) - +new Date();
    let parts;

    if (Number.isNaN(remainingSeconds)) {
      this.stopTimer();
    } else {
      if (remainingSeconds <= 0) {
        parts = { days: 0, hours: 0, minutes: 0, seconds: 0 };
        this.stopTimer();
      } else {
        parts = {
          days: Math.floor(remainingSeconds / (1000 * 60 * 60 * 24)),
          hours: Math.floor((remainingSeconds / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((remainingSeconds / 1000 / 60) % 60),
          seconds: Math.floor((remainingSeconds / 1000) % 60),
        };
      }

      if (this.hasDaysBlockTarget) {
        this.daysTarget.innerText = String(parts.days).padStart(this.daysPaddingZeroesValue, "0");
      }

      this.hoursTarget.innerText = String(parts.hours).padStart(this.paddingZeroesValue, "0");
      this.minutesTarget.innerText = String(parts.minutes).padStart(this.paddingZeroesValue, "0");
      this.secondsTarget.innerText = String(parts.seconds).padStart(this.paddingZeroesValue, "0");
    }
  }

  stopTimer() {
    if (!this.timer) return;

    clearInterval(this.timer);
  }

  disconnect() {
    this.stopTimer();
  }
}
