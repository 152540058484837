import { addAmplitudeParentProps, readAmplitudeProps, trackAmplitudeEvents } from "./amplitude";
import { trackGTMEvents } from "./gtm";

/**
 * Creates an EventListener for the element received, executing the callback function provided
 * You can pass custom events to be listened to, the default is 'click'
 * @param {*} element
 * @param {*} callbackTrackerFn
 * @param {*} event - Default: 'click'
 */
export function createEventListener(element, callbackTrackerFn, event = "click") {
  if (!element) {
    throw Error("The element is mandatory for registering an Event Listener");
  }

  if (!callbackTrackerFn) {
    throw Error("The callback function is mandatory for registering an Event Listener");
  }

  element.addEventListener(event, callbackTrackerFn);
}

/**
 * Register the Trackers given the attributes on Element's DataSet
 * @param {*} element
 * @param {*} defaultEventValues
 */
function registerTrackersForElement(element) {
  // Only if having either GA or Amplitude registered as trackers, we then create the listener
  createEventListener(element, (event) => {
    // TRACK A ELEMENT
    // event.stopPropagation();

    // You can track an element just using his selector and get the data from data-attributes
    const { amplitudeParentPropsSelector } = element.dataset;

    let { amplitudeProps } = element.dataset;
    const { gtmProps } = element.dataset;

    const elementTrackers = [];

    // Google Tag Manager Tracking
    if (gtmProps) {
      elementTrackers.push(() => trackGTMEvents(gtmProps));
    }

    // Amplitude Tracking
    if (amplitudeProps) {
      elementTrackers.push(() => {
        if (amplitudeParentPropsSelector) {
          const amplitudeParentElement = document.getElementById(amplitudeParentPropsSelector);
          amplitudeProps = addAmplitudeParentProps(amplitudeParentElement, amplitudeProps);
        }

        const { eventName, eventProperties } = readAmplitudeProps(amplitudeProps);
        trackAmplitudeEvents(eventName, eventProperties);
      });
    }

    if (elementTrackers.length) {
      elementTrackers.forEach((eventTracker) => {
        if (window.scheduler) {
          window.scheduler.postTask(eventTracker, { priority: "background" });
        } else {
          eventTracker();
        }
      });
    }
  });
}

/**
 * Track a Component (GA and/or Amplitude) given a selector
 * @param {*} trackerParams
 */
export const trackComponent = ({
  // ONE SELECTOR
  selector,
  category = null,
  action = null,
  label = null,

  // CHILDREN
  // Options for children if there are
  childrenSelector = ".js-track-event",
  childrenDefaultCategory = category,
  childrenDefaultAction = null, // Default action for children when it has no 'data-track' attributes but it has 'childrenSelector'
}) => {
  const element = document.querySelector(selector);
  if (!element) {
    return;
  }

  registerTrackersForElement(element, { category, action, label });

  // TRACK CHILDREN
  // You can track childrend just using the 'childrenSelector' class name
  const childrenElements = element.querySelectorAll(childrenSelector);
  if (childrenElements && childrenElements.length) {
    childrenElements.forEach((childElem) => {
      registerTrackersForElement(childElem, {
        category: childrenDefaultCategory,
        action: childrenDefaultAction,
      });
    });
  }
};

/**
 * Generic Elements Tracker (GA and/or Amplitude), given a selector
 * This serves the purpose of globally tracking any element using GA class tracker or Amplitude class tracker
 * Note: only attributes on the DOM element will be used as properties, see below:
 * GA DOM attributes:
 *  - {string} trackCategory
 *  - {string} trackAction
 *  - {string} trackLabel
 * Amplitude DOM attributes:
 *  - {Object} amplitudeProps - { event_name: string; event_properties: any{} }
 * @param {string} selector
 */
export const trackMultipleComponents = (selector) => {
  selector = selector.split(",").map( item_selector => item_selector + ":not(.js-component-tracked)").join(",")

  const elements = document.querySelectorAll(selector);
  if (!elements) {
    return;
  }

  elements.forEach((element) => {
    registerTrackersForElement(element);
    element.classList.add('js-component-tracked');
  });
};
