import * as cookiesUtils from "../../../packs/utils/cookies";

const collapsedClass = "collapsed";
const collapsedStatusCookie = "dmstkAlertJuguetonaCollapsed";
const closedStatusCookie = "dmstkAlertJuguetonaClosed";
/**
 * Sets up click event handler for alert juguetona molecules toggle/close buttons.
 * It will add or remove the collapsed CSS class to the parent div.
 *
 */
const AlertJuguetona = () => {
  const toggleButtons = document.querySelectorAll(".js-alert-juguetona-toggle");
  const closeButtons = document.querySelectorAll(".js-alert-juguetona-close");
  /**
   * For expandible variant: event to add or remove the collapsed CSS class to the parent div and update the cookie controlling that info
   *
   */
  Array.from(toggleButtons).forEach((button) => {
    button.addEventListener("click", async (event) => {
      const parentDiv = event.target.closest(".js-alert-juguetona-collapsible");

      if (!parentDiv) {
        return;
      }

      parentDiv.classList.toggle(collapsedClass);
      // we need to store in a cookie this value - expiration in 4000 days (infinite)
      // the view will render the alert with or without the collapsedClass depending on this cookie
      cookiesUtils.setCookie(
        collapsedStatusCookie,
        parentDiv.classList.contains(collapsedClass),
        4000
      );
    });
  });
  /**
   * For closing variant: it will remove the alert juguetona on close
   *
   */
  Array.from(closeButtons).forEach((button) => {
    button.addEventListener("click", async (event) => {
      const parentDiv = event.target.closest(".js-alert-juguetona-closable");

      if (!parentDiv) {
        return;
      }

      parentDiv.remove();
      cookiesUtils.setCookie(closedStatusCookie, true, 90);
    });
  });
};

AlertJuguetona();
