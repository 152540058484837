/**
 * This function closes an ancestor modal, if it exists, meaning it applies a CSS class (show).
 *
 * @param {Element} targetElement - the DOM Element where the click event was detected.
 */
export const closeModal = targetElement => {
  const ancestorModal = targetElement.closest('.js-modal, .js-modal-default-placeholder')

  if (!ancestorModal) return

  // we need to remove its inner content:
  ancestorModal.innerHTML = null
  ancestorModal.classList.remove('show')

  // we remove the body class to avoid scrollbars
  document.querySelector('body').classList.remove('modal-open')

  // we dispatch a close event with the name
  document.dispatchEvent(new Event(`modal.${ancestorModal.dataset.modalName}.close`))
}

/**
 * This function opens an ancestor modal, if it exists, meaning it removes a CSS class (show).
 *
 * @param {Element} targetElement - the DOM Element where the click event was detected.
 */
export const openModal = (targetElement, htmlContent) => {
  const { modalToShow } = targetElement.dataset

  const modalsCandidates = document.getElementsByClassName(modalToShow)

  if (!modalsCandidates[0]) {
    return
  }

  const modal = modalsCandidates[0]

  // we add class to body to avoid scrollbars
  document.querySelector('body').classList.add('modal-open')

  modal.innerHTML = htmlContent
  modal.classList.add('show')

  if (modal.firstChild.classList.contains('js-has-tracking')) {
    Domestika.startGlobalEventsTracker()
  }

  if (modal.firstChild.classList.contains('js-has-amplitude-event')) {
    const { eventName, eventProperties } = JSON.parse(modal.firstChild.dataset.amplitudeProps);
    Domestika.amplitude.trackEvent(eventName, eventProperties);
  }
}
