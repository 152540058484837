/* eslint-disable no-undef */
/**
 * Copies some text to the clipboard.
 * @param {string} text - The text to copy.
 * @param {string} event - The event that triggered this function.
 * @returns {Promise} - The result of writing text in the clipboard via navigator, or a resolved Promise otherwise.
 */

export const copyToClipboard = (text, event) => {
  if (navigator.clipboard) {
    return navigator.clipboard.writeText(text);
  }

  event.clipboardData.setData("text/plain", text);

  return Promise.resolve();
};

/**
 * iOS helper. Creates a hidden textarea with the text to be copied.
 * @param {string} text - The text to copy.
 * @param {string} event - The event that triggered this function.
 * @returns {Element} - The created textarea.
 */

export const createTextArea = (text) => {
  const textArea = document.createElement("textArea");
  textArea.setAttribute("readonly", true);
  textArea.contentEditable = true;
  textArea.value = text;

  document.body.appendChild(textArea);
  return textArea;
};

/**
 * iOS helper. Selects text, as it is mandatory in iOS, but in a way the keyboard is not shown.
 * @param {Element} textArea - The textArea with the text to copy.
 */

export const selectText = (textArea) => {
  const range = document.createRange();
  const selection = window.getSelection();

  range.selectNodeContents(textArea);

  selection.removeAllRanges();
  selection.addRange(range);

  textArea.setSelectionRange(0, 999999);
};
