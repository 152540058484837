// Modal global/single placeholder
const $modalCourse = document.querySelector("#js-modal-course");

// cleanModal - Function to clean the content of a modal that we send
const cleanModal = ($modal) => {
  const $content = $modal.querySelector(".modal-content");
  if ($modal.contains($content)) $content.innerHTML = "";
};

// Define event to clean the window when we close it
if (document.contains($modalCourse))
  $($modalCourse).on("hidden.bs.modal", () => {
    cleanModal($modalCourse);
  });
