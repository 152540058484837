import { getLang } from "../functions";
import { config } from "./config";
import * as NotificationsConstants from "./constants";
import * as NotificationsServices from "./services";

export class DomestikaPushNotification {
  constructor() {
    if (!this.isEnabled()) {
      return;
    }

    this.pushNotificationsRegistered = false;

    if (
      NotificationsServices.isNotificationPermissionGranted({
        permission: window.Notification.permission,
      })
    ) {
      this.register();
    }
  }

  static create() {
    return new DomestikaPushNotification();
  }

  isEnabled = () =>
    window?.ENV?.PUSH_NOTIFICATION_ENABLED && NotificationsServices.browserHasNotificationAPI();

  isPermissionDenied = ({ permission }) => {
    return NotificationsServices.isNotificationPermissionDenied({ permission });
  };

  isPermissionGranted = ({ permission }) => {
    return NotificationsServices.isNotificationPermissionGranted({ permission });
  };

  getSettings = () => NotificationsServices.getPushNotificationSettings() || {};

  updateSettings = ({ settings }) => {
    NotificationsServices.setPushNotificationSettings({ settings });
  };

  registerMessagesListener = ({ swRegistration }) => {
    NotificationsServices.registerFirebaseOnMessage({
      callback: (payload) => {
        const { title, body } = payload?.notification || {};
        const { link, tag, icon } = payload?.data || {};
        swRegistration.showNotification(title, { body, icon, tag, data: { link } });
      },
    });

    this.pushNotificationsRegistered = true;
  };

  registerDevice = async ({ swRegistration }) => {
    const token = await NotificationsServices.getFirebaseToken({ swRegistration });
    if (!token) {
      return false;
    }

    const { tokenId: currentTokenId, token: currentToken } = this.getSettings();
    if (currentToken === token) {
      return true;
    }

    const domain = await NotificationsServices.getDomestikaDomain();
    if (!domain) {
      return false;
    }

    if (currentTokenId) {
      await domain.DeleteDeviceUseCase.execute({
        id: currentTokenId,
      });
    }

    const [error, newDeviceRegistration] = await domain.CreateDeviceUseCase.execute({
      token,
      locale: getLang,
      platform: NotificationsConstants.PUSH_NOTIFICATIONS_PLATFORM,
    });

    if (error) {
      /* eslint-disable-next-line no-console */
      console.warn("Couldn't register Notifications!", error);
      return false;
    }

    NotificationsServices.setPushNotificationToken({ tokenId: newDeviceRegistration.id, token });
    return true;
  };

  setupFirebase = async () => {
    if (!window?.navigator?.serviceWorker) {
      /* eslint-disable-next-line no-console */
      console.warn("This browser doesn't support Notifications!");
      return;
    }

    await NotificationsServices.initializeFirebaseApp();
    window.navigator.serviceWorker
      .register(config.SERVICE_WORKER_PATH)
      .then(async (swRegistration) => {
        const deviceRegistered = await this.registerDevice({ swRegistration });
        if (deviceRegistered) {
          this.registerMessagesListener({ swRegistration });
        }
      })
      .catch((error) => {
        /* eslint-disable-next-line no-console */
        console.warn("Couldn't register Notifications!", error);
      });
  };

  register = async () => {
    if (!this.isEnabled()) {
      return;
    }

    if (
      !NotificationsServices.isNotificationPermissionGranted({
        permission: window.Notification.permission,
      })
    ) {
      return;
    }

    if (this.pushNotificationsRegistered) {
      return;
    }

    await this.setupFirebase();
  };
}
