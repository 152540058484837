const loginModals = document.querySelectorAll('[data-target="#login-modal"]');
const signupModals = document.querySelectorAll('[data-target="#signup-modal"]');
if (loginModals) {
  loginModals.forEach((element) => {
    // eslint-disable-next-line no-param-reassign
    element.href = `${element.href}&from_modal=true`;
  });
}

if (signupModals) {
  signupModals.forEach((element) => {
    // eslint-disable-next-line no-param-reassign
    element.href = `${element.href}&from_modal=true`;
  });
}
