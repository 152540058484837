import { requestApi } from "../../utils/requests";
import { createID, getRequestConfiguration } from "../../utils/functions";
import {
  setContent,
  setAlignment,
  hideAllPopoverTooltips,
  POPOVER,
  TOOLTIP,
  TOP,
  END,
  START,
} from "./utils";

export const TIMEOUT = 150; // timeout to use on hide/show to smooth transition

/** Class representing a PopoverTooltip component.
 *
 * To use it:
 *
 * const popoverTooltip = new PopoverTooltip();
 * popoverTooltip.show(<DOMelementToRenderThePopoverTooltipBy>);
 *
 * The hide method will remove the popoverTooltip from the DOM.
 */
export class PopoverTooltip {
  /**
   * Set up some popoverTooltip's variables.
   *
   * @param {Object} configuration - Object containing the set up configuration for the PopoverTooltip instance.
   * @param {String} [type=tooltip] - The type of the more info element.
   * @param {String} [position=top] - The position of the popoverTooltip.
   * @param {String} [content=I'm a PopoverTooltip] - The content to print. It can be some text or a partial name (only valid for popovers).
   * @param {String} id - The desired popoverTooltip's ID.
   * @param {String} alignment - The desired alignment ("start", "center" or "end"). Only used in popovers.
   * @param {Object} data - Additional data to be passed as query parameters in the request (only valid for popovers).
   */
  constructor(configuration = {}) {
    const { type, content, isStatic, position, id, alignment, data } = configuration;

    this.type = type?.toLowerCase() === POPOVER ? POPOVER : TOOLTIP; // make sure we don't introduce a not supported type
    this.content = setContent(this.type, content);
    this.isStatic = isStatic || false;
    this.position = position || TOP;
    this.ID = id || createID("dmstkPopoverTooltip");
    this.alignment = setAlignment(alignment);

    // show and hide timers to show and hide in a smoother way
    this.showTimer = null;
    this.hideTimer = null;

    this.htmlElement = null;
    // to control wether the mouse is over the popoverTooltip or not
    this.isMouseOver = false;

    this.data = data;
  }

  /**
   * Creates the HTML structure of the popoverTooltip and injects it in the body with the prop visible set to false.
   * We need to do a first addition of the popoverTooltip, invisible, to get its size, so we can
   * position it correctly afterwords.
   *
   */
  async createInvisiblePopoverTooltip() {
    const { type, position, ID, alignment } = this;

    const popoverTooltip = document.createElement("div");
    popoverTooltip.classList.add(`a-${type}`);
    popoverTooltip.classList.add(`a-${type}--${alignment}`);
    popoverTooltip.classList.add("show");

    popoverTooltip.setAttribute("role", type);
    popoverTooltip.setAttribute("id", ID);
    popoverTooltip.setAttribute("x-placement", position);

    // inner elements:
    const popoverTooltipArrow = document.createElement("div");
    popoverTooltipArrow.classList.add(`a-${type}__arrow`);
    popoverTooltip.appendChild(popoverTooltipArrow);

    const popoverTooltipInner = document.createElement("div");
    popoverTooltipInner.classList.add(`a-${type}__inner`);

    // create the inner content -- different between popovers and tooltips

    if (type === POPOVER) {
      if (this.isStatic) {
        const contentSelector = document.getElementById(this.content);
        popoverTooltipInner.innerHTML = contentSelector.innerHTML;
      } else {
        // here we call the partial via HTTP GET
        const configuration = getRequestConfiguration("/popovers", {
          partialToRender: this.content,
          ...this.data,
        });

        try {
          const popoverInnerContent = await requestApi(configuration);
          // inject html content in the corresponding target
          popoverTooltipInner.innerHTML = popoverInnerContent.data.trim();
        } catch (error) {
          throw new Error(`error injecting modal HTML: ${error}`);
        }
      }
    }

    if (type === TOOLTIP) {
      popoverTooltipInner.innerHTML = this.content;
    }

    popoverTooltip.appendChild(popoverTooltipInner);

    popoverTooltip.style.visible = false;
    popoverTooltip.style.position = "absolute";
    popoverTooltip.style.willChange = "transform";
    popoverTooltip.style.top = "0px";
    popoverTooltip.style.left = "0px";

    // add popoverTooltip to the corresponding container
    document.body.appendChild(popoverTooltip);
    this.htmlElement = popoverTooltip;
  }

  /**
   * Applies position via CSS class to the popoverTooltip element. We create this separate function
   * to be called after calculating the popoverTooltip's position, as we'll try to place it where it was
   * first configured but there's an implemented fallback if the popoverTooltip gets outside the viewport.
   *
   * @param {Object} popoverTooltip - The popoverTooltip DOM element.
   * @return {number}
   */

  applyPosition() {
    this.htmlElement.classList.add(`a-${this.type}--${this.position}`);
  }

  /**
   * Calculates the translation in the X axis.
   *
   * @param {Object} popoverTooltipRect - The current size and position of the popoverTooltip.
   * @param {Object} elementRect - The size and position of the element we'll place the popoverTooltip close to.
   * @return {number}
   */
  getXTranslation(popoverTooltipRect, elementRect) {
    // get scroll
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const minimumX = window.pageXOffset;
    const maximumX = window.pageXOffset + window.innerWidth;

    let margin = 0;
    let marginLeft = 8;

    if (this.type === POPOVER) {
      margin = 12;
      marginLeft = 12;
    }

    if (this.position === "left") {
      const xPosition = elementRect.left + scrollLeft - marginLeft - popoverTooltipRect.width;

      // if the popoverTooltip gets outside the viewport, we try to change its postion to its opposite
      if (xPosition < minimumX) {
        const oppositeX = elementRect.right + margin + scrollLeft;

        if (oppositeX + popoverTooltipRect.width <= maximumX) {
          this.position = "right";
          return oppositeX;
        }
      }

      return xPosition;
    }

    if (this.position === "right") {
      const xPosition = elementRect.right + margin + scrollLeft;

      // if the popoverTooltip gets outside the viewport, we try to change its postion to its opposite
      if (xPosition + popoverTooltipRect.width > maximumX) {
        const oppositeX = elementRect.left + scrollLeft - marginLeft - popoverTooltipRect.width;

        if (oppositeX >= minimumX) {
          this.position = "left";
          return oppositeX;
        }
      }

      return xPosition;
    }

    // popovers have alignment applied over the X axis
    if (this.type === POPOVER) {
      if (this.alignment === START) {
        return elementRect.left + scrollLeft;
      }
      if (this.alignment === END) {
        return elementRect.right - popoverTooltipRect.width;
      }
    }

    // we place the popoverTooltip horizontally aligned the middle of the target element
    const hAlignment = elementRect.width / 2 - popoverTooltipRect.width / 2;
    return elementRect.left + hAlignment + scrollLeft;
  }

  /**
   * Calculates the translation in the Y axis.
   *
   * @param {Object} popoverTooltipRect - The current size and position of the popoverTooltip.
   * @param {Object} elementRect - The size and position of the element we'll place the popoverTooltip close to.
   * @return {number}
   */
  getYTranslation(popoverTooltipRect, elementRect) {
    // get scroll
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const minimumY = window.pageYOffset;
    const maximumY = window.pageYOffset + window.innerHeight;

    let margin = 0;
    let marginTop = 8;

    if (this.type === POPOVER) {
      margin = 12;
      marginTop = 12;
    }

    if (this.position === "top") {
      const yPosition = elementRect.top + scrollTop - marginTop - popoverTooltipRect.height;

      // if the popoverTooltip gets outside the viewport, we try to change its postion to its opposite
      if (yPosition < minimumY) {
        const oppositeY = elementRect.bottom + margin + scrollTop;

        if (oppositeY + popoverTooltipRect.height <= maximumY) {
          this.position = "bottom";
          return oppositeY;
        }
      }
      return yPosition;
    }

    if (this.position === "bottom") {
      const yPosition = elementRect.bottom + margin + scrollTop;

      // if the popoverTooltip gets outside the viewport, we try to change its postion to its opposite
      if (yPosition + popoverTooltipRect.height > maximumY) {
        const oppositeY = elementRect.top + scrollTop - margin - popoverTooltipRect.height;

        if (oppositeY >= minimumY) {
          this.position = "top";
          return oppositeY;
        }
      }
      return yPosition;
    }

    // calculate vertical alignment
    const vAlignment = elementRect.height / 2 - popoverTooltipRect.height / 2;
    return elementRect.top + vAlignment + scrollTop;
  }

  /**
   * Calculates the popoverTooltip's coordinates. Converts the coordinates to integer, as it
   * produces some blurry effect in some browsers.
   *
   * @param {Object} popoverTooltipRect - The current size and position of the popoverTooltip.
   * @param {Object} elementRect - The size and position of the element we'll place the popoverTooltip close to.
   * @return {Object} with x and y coordinates.
   */
  getCoordinates(popoverTooltipRect, elementRect) {
    const x = Math.trunc(this.getXTranslation(popoverTooltipRect, elementRect));
    const y = Math.trunc(this.getYTranslation(popoverTooltipRect, elementRect));

    return { x, y };
  }

  /**
   * Shows the popoverTooltip relative to a certain element, at the top, bottom, left or right of it.
   * It will be injected in the body and translated afterwards to the correct position.
   *
   * @param {Element} relatedElement - The element to place the popoverTooltip close to.
   */
  async show(relatedElement) {
    // remove all open PopoverTooltips
    hideAllPopoverTooltips();

    // get related element position and size
    const elementRect = relatedElement.getBoundingClientRect();

    await this.createInvisiblePopoverTooltip();
    const popoverTooltip = this.htmlElement;

    // get popoverTooltip position and size
    const popoverTooltipRect = popoverTooltip.getBoundingClientRect();

    // calculate the new position for the popoverTooltip
    const coordinates = this.getCoordinates(popoverTooltipRect, elementRect);

    // apply position via CSS class - it could've changed in the previous function
    this.applyPosition();

    // apply transformation - popover no
    popoverTooltip.style.transform = `translate3d(${coordinates.x}px, ${coordinates.y}px, 0px)`;

    this.showTimer = setTimeout(() => {
      popoverTooltip.style.visible = true; // change visibility
    }, TIMEOUT);
    clearTimeout(this.hideTimer);

    // and attach two events to show/hide the popover
    popoverTooltip.addEventListener("mouseenter", () => {
      this.isMouseOver = true;
    });

    popoverTooltip.addEventListener("mouseleave", () => {
      this.isMouseOver = false;
      this.hide();
    });
  }

  /**
   * Removes the popoverTooltip from the DOM.
   */
  hide() {
    const popoverTooltip = document.getElementById(this.ID);
    if (!popoverTooltip) {
      return;
    }

    // if the mouse is over, we don't hide it
    if (this.isMouseOver) {
      return;
    }

    this.hideTimer = setTimeout(() => {
      // remove all open PopoverTooltips
      hideAllPopoverTooltips();
    }, TIMEOUT);

    clearTimeout(this.showTimer);
  }
}
