class Link {
  constructor() {
    this.selector = ".js-link";
  }

  init() {
    const links = document.querySelectorAll(this.selector) || [];
    links.forEach((link) => {
      const href = link.dataset.href || link.querySelector("[data-href]").dataset.href;
      if (href) {
        link.style.cursor = "pointer"; // eslint-disable-line no-param-reassign
        link.addEventListener("click", (event) => {
          event.preventDefault();
          window.location.href = href;
        });
      }
    });
  }
}

try {
  Domestika.link = new Link();
  Domestika.link.init();
} catch (error) {
  console.error("[Domestika.link]", error);
}
