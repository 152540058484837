// Función genérica para llamar al track eventos actual
export const trackGTMEvents = (gtmProps) => {
  const obj = JSON.parse(gtmProps);
  const arrObj = Array.isArray(obj) ? obj : [obj];
  arrObj.forEach((track) => {
    Domestika.datalayer.push({
      event: track.event,
      ...track.attributes,
    });
  });
};
