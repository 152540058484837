const customUUIDv4 = () => {
  // Based on https://stackoverflow.com/a/2117523
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

export const UUIDv4 = () => {
  if (!(crypto.randomUUID instanceof Function)) {
    return customUUIDv4();
  }

  return crypto.randomUUID();
};
