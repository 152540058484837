import * as NotificationsConstants from "./constants";
import { getLang } from "../functions";

export const browserHasNotificationAPI = () => !!window?.Notification;

export const getPushNotificationSettings = () => {
  const localStorageItem = localStorage.getItem(
    NotificationsConstants.PUSH_NOTIFICATIONS_SETTINGS_KEY
  );

  if (!localStorageItem) {
    return null;
  }

  return JSON.parse(localStorageItem);
};

export const setPushNotificationSettings = ({ settings }) => {
  localStorage.setItem(
    NotificationsConstants.PUSH_NOTIFICATIONS_SETTINGS_KEY,
    JSON.stringify(settings)
  );
};

export const setPushNotificationToken = ({ tokenId, token }) => {
  const pushNotificationSettings = getPushNotificationSettings() || {};
  setPushNotificationSettings({
    settings: {
      ...pushNotificationSettings,
      tokenId,
      token,
    },
  });
};

export const isNotificationPermissionGranted = ({ permission }) =>
  permission === NotificationsConstants.GRANTED;

export const isNotificationPermissionDenied = ({ permission }) =>
  permission === NotificationsConstants.DENIED;

export const isNotificationPermissionDefault = ({ permission }) =>
  permission === NotificationsConstants.DEFAULT;

export const getDomestikaDomain = async () => {
  try {
    const { Domestika } = await import("@web/domestika");
    return Domestika.create({
      API_PERFORMANCE_METRICS: [],
      LANGUAGE: getLang,
      API_URL: window.ENV.API_URL,
      API_LEGACY_URL: window.ENV.API_LEGACY_URL,
      API_AUTH_URL: window.ENV.API_AUTH_URL,
      APP_URL: window.ENV.APP_URL,
    });
  } catch {
    /* eslint-disable-next-line no-console */
    console.warn("Couldn't register Notifications!");
    return null;
  }
};

let FirebaseSDK = null;
const getFirebaseSDK = async () => {
  if (FirebaseSDK) {
    return FirebaseSDK;
  }

  const { initializeApp } = await import("firebase/app");
  const { getMessaging, getToken, onMessage } = await import("firebase/messaging");
  FirebaseSDK = {
    initializeApp,
    getMessaging,
    getToken,
    onMessage,
  };

  return FirebaseSDK;
};

const getFirebaseConfig = () => {
  const { apiKey, authDomain, projectId, appId, messagingSenderId } = window?.ENV?.FIREBASE || {};
  return {
    apiKey,
    authDomain,
    projectId,
    appId,
    messagingSenderId,
  };
};

export const initializeFirebaseApp = async () => {
  const { initializeApp } = await getFirebaseSDK();
  initializeApp(getFirebaseConfig());
};

export const getFirebaseToken = async ({ swRegistration }) => {
  const { getMessaging, getToken } = await getFirebaseSDK();
  const messaging = getMessaging();
  const token = await getToken(messaging, {
    vapidKey: window?.ENV?.FIREBASE?.vapidKey,
    serviceWorkerRegistration: swRegistration,
  }).catch((error) => {
    /* eslint-disable-next-line no-console */
    console.warn("Couldn't register Notifications!", error);
    return null;
  });

  return token;
};

export const registerFirebaseOnMessage = async ({ callback }) => {
  const { getMessaging, onMessage } = await getFirebaseSDK();
  const messaging = getMessaging();
  onMessage(messaging, callback);
};
