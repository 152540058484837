import { requestApi } from '../../utils/requests'
import { hasSomeParentWithClass, getRequestConfiguration } from '../../utils/functions'
import { closeModal, openModal } from './utils'
import { copyField } from '../../molecules/fields/copyToClipboardField'

async function fetchAndOpenModal(target) {
  // build the request configuration using the data attributes of the trigger element
  const serviceConfiguration = getRequestConfiguration('/modals', target.dataset)

  try {
    const htmlContent = await requestApi(serviceConfiguration)
    // inject html content in the corresponding target
    openModal(target, htmlContent.data.trim()) // open the modal if the request was successfull
    // eslint-disable-next-line no-undef
    document.dispatchEvent(new Event(`modal.${target.dataset.modalName}.open`))
    copyField()
  } catch (error) {
    throw new Error(`error injecting modal HTML: ${error}`)
  }
}

const Modal = () => {
  // FIXME: this is capturing all clicks in the whole page
  document.body.addEventListener('click', async event => {
    const { target } = event

    // click in an element that opens a modal
    if (target.classList.contains('js-show-modal')) {
      event.preventDefault()
      fetchAndOpenModal(target)
    }

    const isOnModalClose = hasSomeParentWithClass(target, 'js-close-modal')
    const isOnSpecificModal = hasSomeParentWithClass(target, 'js-modal')
    const isOnDefaultModal = hasSomeParentWithClass(target, 'js-modal-default-placeholder')
    const isOnModalContent = hasSomeParentWithClass(target, 'js-modal-content')
    const isOnModalOverlay = (isOnSpecificModal || isOnDefaultModal) && !isOnModalContent
    const isOnModalConfirm = hasSomeParentWithClass(target, 'js-confirm-modal')

    if (isOnModalClose || isOnModalOverlay || isOnModalConfirm) {
      setTimeout(() => closeModal(target), 0) // async, required when form is submitted
    }
  })

  // Detect if there's any modal to be open on page load and open it
  const modalsToOpen = document.querySelectorAll('.js-show-modal-now')

  modalsToOpen.forEach(modalToOpen => {
    const delay = parseInt(modalToOpen.dataset.showModalNowDelay, 10) || 0
    setTimeout(() => {
      fetchAndOpenModal(modalToOpen)
    }, delay)
  })
}

Domestika.Modal = Modal

Modal()
