// constants for type
export const POPOVER = "popover";
export const TOOLTIP = "tooltip";
// constants for position
export const TOP = "top";
export const BOTTOM = "bottom";
// constants for alignmnet
export const CENTRE = "center";
export const START = "start";
export const END = "end";

/**
 * Given a type of PopoverTooltip and a message, it validates the message and sets it up properly.
 *
 * @param {String} type - either popover or tooltip.
 * @param {String} message - message to be set up.
 */

export const setContent = (type, content) => {
  if (type === TOOLTIP && (!content || typeof content !== "string")) {
    return "I'm a PopoverTooltip";
  }

  if (type === POPOVER && (!content || typeof content !== "string")) {
    return "test_partial";
  }

  return content;
};

/**
 * It validates the PopoverTooltip's alignment and sets it up properly.
 *
 * @param {String} alignment - the specified alignment.
 */

export const setAlignment = (alignment) => {
  const lowerCaseAlignment = alignment?.toLowerCase() || CENTRE;

  if (lowerCaseAlignment !== START && lowerCaseAlignment !== END) {
    return CENTRE;
  }

  return lowerCaseAlignment;
};

/**
 * Removes every PopoverTooltip of the page
 *
 */

export const hideAllPopoverTooltips = () => {
  const popovers = document.querySelectorAll(".a-popover");
  const tooltips = document.querySelectorAll(".a-tooltip");

  popovers.forEach((popover) => {
    popover.parentNode.removeChild(popover);
  });

  tooltips.forEach((tooltip) => {
    tooltip.parentNode.removeChild(tooltip);
  });
};
