import * as cookiesUtils from "./cookies";

function updateUserConsentCookie() {
  if (cookiesUtils.getCookie("user_consent") !== OnetrustActiveGroups) {
    cookiesUtils.setCookie("user_consent", OnetrustActiveGroups, 365);

    if (window.ENV.USER_CONSENT_CHECKER === false) {
      cookiesUtils.removeCookies();
    }
  }
}

function waitForUserConsent() {
  const checkInterval = setInterval(function checkOneTrustAvailability() {
    if (typeof OneTrust !== "undefined") {
      clearInterval(checkInterval);
      if (typeof OnetrustActiveGroups !== "undefined") {
        updateUserConsentCookie();
      }
      OneTrust.OnConsentChanged(updateUserConsentCookie);
    }
  }, 100);
}

waitForUserConsent();
