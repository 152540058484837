import { mostUsedDomains } from "./utils";
import { trackAmplitudeEvents } from "../../../utils/events/amplitude";

const emailCorrectorField = (emailField) => {
  const emailInput = emailField.querySelector("input");
  const messageCopy = emailField.getAttribute("data-email-corrector-copy");
  const delayTimer = 1000;
  let messageDiv;
  let myDelay;
  let amplitudeProps;
  let prevEmailSuggestion;

  // Amplitude
  const emailFieldData = emailField.dataset;

  amplitudeProps = {
    page_uri: emailFieldData.amplitudePropsPrevPageUri,
    page_type: emailFieldData.amplitudePropsPageType,
    page_subtype: emailFieldData.amplitudePropsPageSubtype,
    prev_page_uri: emailFieldData.amplitudePropsPageUri,
    validated_at: "frontend",
    error_field: "email",
  };

  function getNextSibling(elem, selector) {
    // Get the next sibling element
    let sibling = elem.nextElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.nextElementSibling;
    }

    return sibling;
  }

  function createMessage() {
    if (messageDiv) return;

    messageDiv = document.createElement("div");
    messageDiv.id = "email-suggestion";
    messageDiv.classList.add("o-email-corrector__message");
  }

  function destroyMessage() {
    if (messageDiv) {
      messageDiv.classList.add("d-none");
    }
  }

  function clearRailsFeedback(item) {
    const feedback = getNextSibling(item, ".invalid-feedback");

    if (!feedback) return;

    item.classList.remove("is-invalid");
    feedback.remove();
  }

  function fillValue(value) {
    emailInput.value = `${emailInput.value.split("@")[0]}@${value}`;
    emailInput.dispatchEvent(new Event("change"));

    destroyMessage();

    // Amplitude correct email event
    trackAmplitudeEvents("signup_email_suggestion_clicked", amplitudeProps);
  }

  async function getSuggestion(value) {
    const { default: Fuse } = await import("fuse.js");

    const fuse = new Fuse(mostUsedDomains, {
      shouldSort: true,
      threshold: 0.3,
      location: 0,
      distance: 10,
      maxPatternLength: 32,
      minMatchCharLength: 2,
    });

    const filteredDomains = fuse.search(value);
    if (filteredDomains.length !== 0) {
      return filteredDomains[0].item;
    }

    return false;
  }

  async function showMessage(value) {
    // Check if domain is in list
    if (mostUsedDomains.includes(value)) {
      return false;
    }

    const domainSuggested = await getSuggestion(value);
    if (!domainSuggested) return false;

    createMessage();

    messageDiv.innerHTML = messageCopy.replace("[DOMAIN]", domainSuggested);
    messageDiv.dataset.domain = domainSuggested;
    messageDiv.classList.remove("d-none");
    emailInput.after(messageDiv);

    // Amplitude show message event
    const shouldUpdateAmplitude = prevEmailSuggestion !== domainSuggested;
    if (shouldUpdateAmplitude) {
      amplitudeProps = { ...amplitudeProps, suggested_value: domainSuggested };
      trackAmplitudeEvents("signup_email_suggestion_viewed", amplitudeProps);
    }

    prevEmailSuggestion = domainSuggested;

    messageDiv.addEventListener("click", () => {
      fillValue(domainSuggested);
    });

    return true;
  }

  function checkValue(event) {
    destroyMessage();
    const { value } = event.target;

    if (
      value.indexOf("@") !== -1 &&
      value.split("@")[0] !== "" &&
      emailInput.hasAttribute("data-valid")
    ) {
      clearRailsFeedback(event.target);
      showMessage(value.split("@")[1]);
    } else {
      destroyMessage();
    }
  }

  function handleInput(event) {
    clearTimeout(myDelay);
    myDelay = setTimeout(() => {
      checkValue(event);
    }, delayTimer);
  }

  emailInput.addEventListener("input", handleInput, false);
};

export const emailCorrectorFieldsInit = () => {
  const emailFields = document.querySelectorAll(".js-email-corrector");

  Array.from(emailFields).forEach((emailField) => {
    emailCorrectorField(emailField);
  });
};

Domestika.emailCorrectorFieldsInit = emailCorrectorFieldsInit;

// Pre-init
Domestika.emailCorrectorFieldsInit();
