import * as cookiesUtils from "../../../packs/utils/cookies";

class stickyBanner {
  // eslint-disable-next-line class-methods-use-this
  init() {
    const stickyBannerElement = document.querySelector(".js-sticky-banner");

    if (stickyBannerElement) {
      const body = document.querySelector("body");
      body.classList.add('js-sticky-banner-on');
      const stickyBannerCookie = stickyBannerElement.dataset.bannerId;
      const stickyBannerCloseButton = stickyBannerElement.querySelector(
        ".js-sticky-banner-close-button"
      );
      document.querySelector("body").classList.add('js-sticky-banner-on');

      if (stickyBannerCloseButton) {
        stickyBannerCloseButton.addEventListener("click", async () => {
          stickyBannerElement.remove();

          cookiesUtils.setCookie(`stickyBannerClosed_${stickyBannerCookie}`, true, 4000);
          body.classList.remove('js-sticky-banner-on');
        });
      }
    }
  }
}

Domestika.stickyBanner = new stickyBanner();

// Pre-init
Domestika.stickyBanner.init();
