class EnterFocusEmail {
  // eslint-disable-next-line class-methods-use-this
  static init() {
    const emailFields = document.querySelectorAll(".js-email-field");

    emailFields.forEach((elem) => {
      elem.addEventListener("keypress", (e) => {
        if (e.key === "Enter" || e.keyCode === 13) {
          e.preventDefault();
          e.target.closest("fieldset").querySelector(".js-password-field").focus();
        }
      });
    });
  }
}

// Pre-init
EnterFocusEmail.init();

// Init when modals are opened
document.addEventListener("signUpModalOpen", () => EnterFocusEmail.init());
document.addEventListener("signInModalOpen", () => EnterFocusEmail.init());
