import { hasSomeChildWithClass } from "../../utils/functions";
import { PopoverTooltip } from "../../atoms/popoverTooltip";

const TIMEOUT = 150;

export class Tooltip {
  constructor() {
    this.tooltip;
    this.hideTimer;
  }

  init() {
    document.body.addEventListener("mouseover", (e) => {
      if (e.target.matches(".js-show-tooltip")) {
        this.showHandler(e.target);
      }
    });
  }

  /**
   * Method for showing a tooltip
   */

  showHandler(tooltipTrigger) {
    if (hasSomeChildWithClass(tooltipTrigger, "a-popover")) {
      return;
    }

    const data = tooltipTrigger.dataset;
    const { tooltipMessage: content, tooltipPosition: position } = data;
    this.tooltip = new PopoverTooltip({
      content,
      position,
      type: "tooltip",
    });

    this.tooltip.show(tooltipTrigger);

    clearTimeout(this.hideTimer);

    tooltipTrigger.addEventListener("mouseleave", this.hideHandler);
  }

  /**
   * Method for hiding a popover
   *
   * Introduce some delay when calling hide, so the user can access the popover
   */

  hideHandler = (event) => {
    const tooltipTrigger = event.target;

    this.hideTimer = setTimeout(() => {
      this.tooltip.hide();
      tooltipTrigger.removeEventListener('mouseleave', this.hideHandler);
    }, TIMEOUT);
  };
};

Domestika.tooltip = new Tooltip();

Domestika.tooltip.init();
