import createFakeLink from "../../../packs/fake-link";
import { readAmplitudeProps, trackAmplitudeEvents } from "../../utils/events/amplitude";

let ALREADY_LISTENED = false;

const hideDropdowns = () => {
  const dropdowns = document.querySelectorAll(".js-dropdown-hover, .js-dropdown-menu");
  dropdowns.forEach((el) => {
    el.classList.remove("show");
  });
};

const trackDropdownMenu = (el) => {
  // Amplitude tracking - show navigation banner
  const menuOption = el.querySelector("li .js-dropdown-menu");
  const amplitudeProps = menuOption?.dataset?.amplitudeProps;

  if (amplitudeProps && typeof amplitudeProps === "string") {
    const { eventName, eventProperties } = readAmplitudeProps(amplitudeProps);
    trackAmplitudeEvents(eventName, eventProperties);
  }
};

const dropdownHandle = (el) => {
  let hideTimer;
  let showTimer;

  el.addEventListener("mouseenter", (event) => {
    const dropdown = event.target;

    trackDropdownMenu(el);

    showTimer = setTimeout(() => {
      // Hide dropdowns
      hideDropdowns();

      // Show hovered dropdown after x time
      dropdown.classList.add("show");
      dropdown.querySelector(".js-dropdown-menu").classList.add("show");
    }, 150);

    clearTimeout(hideTimer);

    if (!ALREADY_LISTENED) {
      createFakeLink(".js-dropdown-course-link");
      ALREADY_LISTENED = true;
    }
  });

  el.addEventListener("mouseleave", (event) => {
    const dropdown = event.target;

    // Hide hovered dropdown after x time
    hideTimer = setTimeout(() => {
      dropdown.classList.remove("show");
      dropdown.querySelector(".js-dropdown-menu").classList.remove("show");
    }, 150);

    clearTimeout(showTimer);
  });
};

const mainNavigationDropdown = () => {
  const dropdownHover = document.querySelectorAll(".js-dropdown-hover");
  dropdownHover.forEach((el) => dropdownHandle(el));
};

mainNavigationDropdown();
