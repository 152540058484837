class CountdownFake {
  constructor() {
    this.selector = ".js-checkout-countdown";
  }

  countdown(el, minutes) {
    let seconds = 60;
    const mins = minutes || 3;
    const counter = el || {};

    const tick = () => {
      const currentMinutes = mins - 1;
      seconds -= 1;

      const date = `${currentMinutes.toString()}m:${seconds < 10 ? "0" : ""}${seconds}s`;
      counter.innerHTML = I18n.t("generic.checkout_ends_in", { date });
      if (seconds > 0) {
        setTimeout(tick, 1000);
      } else if (currentMinutes === 0) {
        counter.innerHTML = I18n.t("generic.checkout_ended");
      } else {
        this.countdown(counter, mins - 1);
      }
    };

    tick();
  }

  init() {
    const counters = document.querySelectorAll(this.selector) || [];
    counters.forEach((counter) => this.countdown(counter, 3));
  }
}

try {
  Domestika.countdownFake = new CountdownFake();
  Domestika.countdownFake.init();
} catch (error) {
  console.error("[Domestika.countdownFake]", error);
}
