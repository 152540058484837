import { hasSomeChildWithClass } from "../../utils/functions";
import { PopoverTooltip } from "../../atoms/popoverTooltip";

const TIMEOUT = 150;
let hideTimer;

/**
 * Helper function for showing a popover
 */

export const showHandler = (event, popover, relatedElement) => {
  const { target } = event;

  if (hasSomeChildWithClass(target, "a-popover")) {
    return;
  }

  popover.show(relatedElement);

  clearTimeout(hideTimer);
};

/**
 * Helper function for hiding a popover
 *
 * Introduce some delay when calling hide, so the user can access the popover
 */

export const hideHandler = (popover) => {
  hideTimer = setTimeout(() => {
    popover.hide();
  }, TIMEOUT);
};

/**
 * Helper function for disabling an event's built-in behaviour
 */

export const handleClick = (event) => {
  event.preventDefault();
};

/**
 * Set up all the popover triggers we find in the document
 *
 * We'll look for all the popover wrappers and attach events to show/hide the corresponding popover.
 * We'll lool for all the popover links, and will desable the click event.
 * We'll disable popovers for tablets and smaller devices.
 *
 */

export const setUpPopovers = () => {
  const popoverLinks = document.querySelectorAll(".js-show-popover");
  popoverLinks.forEach((link) => {
    // disable default behaviour on click
    link.addEventListener("click", handleClick);

    // get popover configuration from the data attributes
    const data = link.dataset;
    const { popoverPosition: position, popoverAlignment: alignment, ...rest } = data;
    const isStatic = !!rest.popoverStaticContent;
    const content = isStatic ? rest.popoverStaticContent : rest.popoverContent;

    const popover = new PopoverTooltip({
      content,
      isStatic,
      position,
      type: "popover",
      alignment,
      data: rest,
    });

    // and attach two events to show/hide the popover
    link.addEventListener("mouseenter", (event) => {
      showHandler(event, popover, link);
    });

    link.addEventListener("mouseleave", () => {
      hideHandler(popover);
    });
  });
};

/**
 * Remove the events handler needed to show/hide popovers.
 *
 */

export const disablePopovers = () => {
  const popoverLinks = document.querySelectorAll(".js-show-popover");
  // remove prevention of default behaviour on click for links
  popoverLinks.forEach((link) => {
    link.removeEventListener("click", handleClick);
    link.removeEventListener("mouseenter", showHandler);
    link.removeEventListener("mouseleave", hideHandler);
  });
};

/**
 * We activate Popovers only for desktop. Disabled for tablets and smaller devices.
 */

enquire.register(Domestika.enquire.tablet_down, { match: disablePopovers });
enquire.register(Domestika.enquire.tablet_up, { match: setUpPopovers });
