import { trackMultipleComponents } from "./trackComponent";

const AmplitudeTrackSelector = ".js-amplitude-track";
const GTMTrackSelector = ".js-gtm-track";

/**
 * Global Tracker for Google Analytics and Amplitude
 * Any DOM element using .js-amplitude-track class, will be tracked by Amplitude
 * Any DOM element using .js-gtm-track class, will be tracked by Google Tag Manager
 * Check available dataset supported for each one of them
 */
const startGlobalEventsTracker = (amplitudeSelector, gaSelector, gtmSelector) => {
  const trackSelectors = [];

  if (amplitudeSelector) {
    trackSelectors.push(amplitudeSelector);
  }
  if (gaSelector) {
    trackSelectors.push(gaSelector);
  }
  if (gtmSelector) {
    trackSelectors.push(gtmSelector);
  }

  if (trackSelectors.length) {
    trackMultipleComponents(trackSelectors.join(","));
  }
};

startGlobalEventsTracker(AmplitudeTrackSelector, GTMTrackSelector);

Domestika.startGlobalEventsTracker = (
  CustomAmplitudeTrackSelector = AmplitudeTrackSelector,
  CustomGTMTrackSelector = GTMTrackSelector
) => {
  startGlobalEventsTracker(CustomAmplitudeTrackSelector, CustomGTMTrackSelector);
};
