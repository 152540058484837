import { Controller } from "@hotwired/stimulus";
import { trackAmplitudeEvents } from "../utils/events/amplitude";

export default class extends Controller {
  static targets = ["searchForm", "inputField", "searchResults", "searchSpinner"];

  static values = {
    isTraditionalSearch: Boolean,
    amplitudeProps: String,
  };

  initialize() {
    this.searchBoxIsLoaded = false;
  }

  connect() {
    if (!this.isTraditionalSearchValue && this.inputFieldTarget.value.length) {
      this.handleSpinner();
      this.initSearchBox();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async initSearchBox() {
    await import("../molecules/search").then(() => {
      this.searchSpinnerTarget.classList.add("d-none");
      this.searchBoxIsLoaded = true;
    });
  }

  handleSpinner() {
    if (!this.searchBoxIsLoaded) {
      this.searchSpinnerTarget.classList.remove("d-none");
    }
  }

  trackEventSearchStarted() {
    if (this.amplitudePropsValue) {
      const eventProperties = JSON.parse(this.amplitudePropsValue);
      trackAmplitudeEvents("search_started", eventProperties);
    }
  }

  showMobileSearchForm() {
    this.searchFormTarget.classList.remove("d-none");
    this.inputFieldTarget.focus();
  }

  resetSearchForm(e) {
    this.searchFormTarget.classList.add("d-none");
    this.searchResultsTarget.classList.remove("show");
    this.inputFieldTarget.value = "";
    this.inputFieldTarget.focus();
    e.target.classList.add("d-none");
  }

  // eslint-disable-next-line class-methods-use-this
  handleSubmit(e) {
    e.preventDefault();
  }
}
