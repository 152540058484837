class CharsCounter {
  init(textAreaSelector, counterSelector) {
    const regExp = /\[\/?(?:b|i|url|email|img|list|code|h|quote|\*)(?:=[^\]\s]+)?\]/g;

    const textAreaElement = document.querySelector(textAreaSelector);
    const messageElement = document.querySelector(counterSelector);

    const updateCharsCounters = () => {
      const valueWithoutBBCodeTags = textAreaElement.value.replaceAll(regExp, "");
      const { maxLength } = textAreaElement;
      const strLength = valueWithoutBBCodeTags.length;

      messageElement.innerHTML = `${strLength} / ${maxLength}`;
    };

    updateCharsCounters();

    textAreaElement.addEventListener("keyup", () => {
      updateCharsCounters();
    });
  }
}

Domestika.charsCounter = new CharsCounter();
