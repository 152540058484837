class showPasswordField {
  // eslint-disable-next-line class-methods-use-this
  init() {
    const passwordField = document.querySelectorAll(".js-show-password");

    Array.from(passwordField).forEach((field) => {
      const button = field.querySelector(".js-show-password-action");

      button.addEventListener("click", async () => {
        const passwordInput = field.querySelector("input");

        if (!passwordInput) {
          return;
        }

        if (passwordInput.type === "password") {
          passwordInput.type = "text";
        } else {
          passwordInput.type = "password";
        }

        const eyeIcons = button.querySelectorAll(".js-show-password-icon");
        eyeIcons.forEach((icon) => {
          icon.classList.toggle("d-none");
        });
      });
    });
  }
}

Domestika.showPasswordField = new showPasswordField();

// Pre-init
Domestika.showPasswordField.init();
