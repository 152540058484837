// TODO: When we delete boostrap modals we can convert this code to vanilla js, now not :(

class Apple {
  // eslint-disable-next-line class-methods-use-this
  init() {
    const appleForms = document.querySelectorAll(".js-apple-sign-in");

    if (appleForms?.length) {
      const data = appleForms[0]?.dataset;
      AppleID.auth.init({
        clientId: data.clientId,
        redirectURI: data.redirectUri,
        scope: data.scope,
        state: data.state,
        usePopup: false,
      });

      appleForms.forEach((form) => {
        form.addEventListener("click", (e) => {
          e.preventDefault();
          AppleID.auth.signIn();
        });
      });
    }
  }
}

if (!Domestika.widgets) {
  Domestika.widgets = {};
}
Domestika.widgets.apple = new Apple();
// Pre-init
Domestika.widgets.apple.init();
