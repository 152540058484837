import { createID } from "../../../utils/functions";
import { PopoverTooltip } from "../../../atoms/popoverTooltip";
import * as utilsFunctions from "./utils";

export const copyField = () => {
  const copyButtons = document.querySelectorAll(".js-copy-to-clipboard");

  Array.from(copyButtons).forEach((button) => {
    button.addEventListener("click", async (event) => {
      const copyableSelector = button.getAttribute("data-clipboard-target");
      const closestCopyableControl = event.target.parentElement.querySelectorAll(copyableSelector);

      if (!closestCopyableControl) {
        return;
      }

      let { value } = closestCopyableControl[0];
      // eslint-disable-next-line no-undef
      const isIOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

      // do the field selection only in desktop to avoid the keyboard display
      if (window.innerWidth > 800 && window.innerHeight > 600) {
        closestCopyableControl[0].select(); // focus the copyable control only in desktop
      }

      // prepare a hidden textarea for iOS
      if (isIOS) {
        const textArea = utilsFunctions.createTextArea(value);
        utilsFunctions.selectText(textArea);
        value = textArea.value;
      }

      // add attribute title to the element that triggered the event
      button.setAttribute("title", "");

      const randomID = createID("tooltip"); // create a random ID for the tooltip
      button.setAttribute("aria-described-by", randomID);

      // finally, copy to clipboard
      try {
        await utilsFunctions.copyToClipboard(value, event);

        // if everything goes alright, show the tooltip:
        const messageForTooltip = button.getAttribute("data-clipboard-success-text"); // it can be an HTML too - rethink the attr name then!!
        const positionForTooltip = button.getAttribute("data-clipboard-success-text-position");

        const tooltip = new PopoverTooltip({
          type: "tooltip",
          content: messageForTooltip,
          position: positionForTooltip,
          id: randomID,
        });
        tooltip.show(button);

        // and attach two events to show/hide the tooltip:

        button.addEventListener("mouseup", () => {
          button.setAttribute("aria-described-by", randomID);
          tooltip.show(button, positionForTooltip);
        });

        button.addEventListener("mouseleave", () => {
          button.removeAttribute("aria-described-by");
          tooltip.hide();
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("copy to clipboard did not work");
      }
    });
  });
};

copyField();
