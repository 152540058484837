import { getLang } from "../functions";

/**
 * It builds the headers content for the HTTP Requests
 * @param {object} headersParams
 * @param {object} headersParams.customHeaders
 * @param {boolean} headersParams.hasFormData
 * @returns {object}
 */
export const getHeaders = ({ customHeaders = {}, hasFormData = false } = {}) =>
  Object.assign(
    !hasFormData
      ? {
          Accept: "application/json",
          "Content-Type": "application/json",
          ...(getLang ? { "Accept-Language": getLang } : {}),
        }
      : {},
    customHeaders
  );
