import { trackComponent } from "../../utils/events/trackComponent";

trackComponent({
  selector: ".js-alert-juguetona-track-event",
  trackIfShown: true,
  ifShownAction: "show-popup",
  childrenDefaultAction: "popup",
  category: "mgm"
});

trackComponent({
  selector: ".js-alert-juguetona-track-event",
  childrenSelector: ".js-alert-juguetona-close",
  childrenDefaultAction: "close-popup",
  category: "mgm"
});
