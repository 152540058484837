const wrappers = document.querySelectorAll('.js-follow-button-wrapper')

Array.from(wrappers).forEach(wrapper => {
  wrapper.addEventListener('click', () => {
    // This class prevents text to change in button right after user starts following another user.
    // Once the mouse is moved outside the button, we then allow to change the text on hover.
    wrapper.classList.add('js-follow-button-just-clicked')
  })

  wrapper.addEventListener('mouseenter', e => {
    const child = e.currentTarget.children[0]

    if (
      !wrapper.classList.contains('js-follow-button-just-clicked') &&
      child.classList.contains('js-unfollow-button-change-text')
    ) {
      const { hoverLinkText } = child.dataset

      child.innerHTML = hoverLinkText
    }
  })

  wrapper.addEventListener('mouseleave', e => {
    const child = e.currentTarget.children[0]

    wrapper.classList.remove('js-follow-button-just-clicked')

    if (child.classList.contains('js-unfollow-button-change-text')) {
      const { disableWith } = child.dataset

      child.innerHTML = disableWith
    }
  })
})
