export const mostUsedDomains = [
  "gmail.com",
  "hotmail.com",
  "yahoo.com",
  "outlook.com",
  "icloud.com",
  "yahoo.es",
  "hotmail.es",
  "live.com",
  "yahoo.com.mx",
  "yahoo.com.br",
  "yahoo.com.ar",
  "me.com",
  "msn.com",
  "live.com.mx",
  "outlook.es",
  "aol.com",
  "hotmail.co.uk",
  "hotmail.it",
  "yahoo.co.uk",
  "gmx.de",
  "mac.com",
  "web.de",
  "googlemail.com",
  "mail.ru",
  "hotmail.com.ar",
  "live.com.ar",
  "hotmail.fr",
  "yahoo.it",
  "libero.it",
  "uol.com.br",
  "ymail.com",
  "yahoo.fr",
  "comcast.net",
  "live.cl",
  "uc.cl",
  "yahoo.de",
  "yandex.ru",
  "btinternet.com",
  "terra.com.br",
];
