/**
 * Validates email format, checking also if the value is null or undefined
 *
 * @param {string} email - the email address to check
 * @return {Boolean} - if the format is correct
 */
export const validateEmail = (email) => {
  if (!email) {
    return false;
  }
  // return if email matches with regular expression
  const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExp.test(String(email).toLowerCase());
};

/**
 * Validates password format
 *  - at least 8 characters
 *
 * @param {string} password - the password to check
 * @return {Boolean} - if the format is correct
 */
export const validatePassword = (password) => {
  if (!password) {
    return false;
  }
  let isLengthValid = password.length >= 8;
  return isLengthValid;
};
