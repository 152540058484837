/* eslint-disable no-param-reassign */
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import {
  amplitudeKey,
  setCookieSessionId,
  getCookieDeviceId,
} from "../utils/amplitude";

class Amplitude {
  constructor() {
    const sessionReplayTracking = sessionReplayPlugin({
      forceSessionTracking: false,
      sampleRate: 1,
    });
    amplitude.add(sessionReplayTracking);
    amplitude.init(amplitudeKey, {
      defaultTracking: {
        attribution: true,
        pageViews: false,
        sessions: true,
        formInteractions: false,
        fileDownloads: false,
      },
      deviceId: getCookieDeviceId(),
    }).promise.then(function() {
      setCookieSessionId(amplitude.getSessionId());
    });
    this.amplitude = amplitude;
    this.setUserProperties();
  }

  setUserProperties() {
    if (!window.AmplitudeData.enable_tracking) {
      return;
    }

    const userData = JSON.parse(decodeURI(atob(window.AmplitudeData.userProperties)));
    const identify = new this.amplitude.Identify();

    if (Object.keys(userData).length) {
      this.amplitude.setUserId(userData.user_id);
      Object.keys(userData).forEach((key) => {
        identify.set(key, userData[key]);
      });
    }

    amplitude.identify(identify);
  }

  trackEvent(eventName, eventProperties) {

    if (!window.AmplitudeData.enable_tracking) {
      return;
    }

    if (typeof(eventName) === 'string') {
      this.amplitude.track(eventName, {
        ...(window.AmplitudeData.mandatoryProperties || {}),
        ...eventProperties,
      });
    } else {
      eventName.event_properties = {
        ...(window.AmplitudeData.mandatoryProperties || {}),
        ...eventName.event_properties,
      };
      this.amplitude.track(eventName, eventProperties);
    }
  }
}

Domestika.amplitude = new Amplitude();
